import React from 'react';
import PropTypes from 'prop-types';

import { 
  disableScroll,
  enableScroll,
} from '../../../helpers/DOMHelpers';

class Timer {
  constructor(callback, delay) {
    this.callback = callback;
    this.remaining = delay;
    this.id = null;
    this.started = null;
    this.running = false;
  }

  start() {
    this.running = true;
    this.started = new Date();
    this.id = setTimeout(this.callback, this.remaining);
  }

  pause() {
    this.running = false;
    clearTimeout(this.id);
    this.remaining -= new Date() - this.started;
  }

  resume() {
    if (!this.getStateRunning()) {
      this.start();
    }
  }  

  stop() {
    this.running = false;
    this.started = null;
    this.remaining = 0;
    clearTimeout(this.id);
  }

  getTimeLeft() {
    if (this.getStateRunning()) {
      this.pause();          
    }
    return this.remaining;
  }

  getStateRunning() {
    return this.running;
  }
}

const isInViewport = (DOMElement) => {
  const rect = DOMElement.getBoundingClientRect();
  const viewportHeight = (window.innerHeight || document.documentElement.clientHeight);
  return (
    rect.bottom >= 0 &&
    rect.top < viewportHeight
  );
};

const sumPreviousSizes = (sizes, index) => {
  let sum = 0;
  Object.keys(sizes).forEach(
    (el) => {
      if (el < index) {
        sum += sizes[el];
      }
    },
  );
  return sum;
};

const propTypes = {
  device: PropTypes.string,
  contentList: PropTypes.instanceOf(Array),
};

const defaultProps = {
  device: '',
  contentList: [],
};

class CPSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSliderWidth: 'auto',
      presentationItemHeight: 'auto', 
    };
    this.currentSlide = 0;
    this.currentMenuSlide = 0;
    this.menuSliderElementsSizes = {};
    this.menuSliderElementsCoords = {};
    this.waitingForASignal = true;
    this.slideTimeout = null;

    this.dir = 'next';
    this.startX = 0;
    this.startY = 0;
    this.deltaX = 0;
    this.deltaY = 0;    
  }

  componentWillReceiveProps(nextProps) {
    this.computeSliderSizes(nextProps.device);
    if (nextProps.device === 'desktop') {
      this.checkViewport();
      if (window !== null) {
        window.addEventListener('scroll', this.checkViewport);
      }    
    }
  }

  componentWillUnmount() {
    if (window !== null) {
      window.removeEventListener('scroll', this.checkViewport);
    }
  }

  checkViewport = () => {
    if (isInViewport(this.scrollCheckElement) && this.waitingForASignal) {
      this.waitingForASignal = false;
      this.goToSlide(0, true);
      window.removeEventListener('scroll', this.checkViewport);
    }
  };

  computeSliderSizes = (device) => {
    let menuSliderWidth = '100%';
    if (device === 'smartphone') {
      menuSliderWidth = 0;
      this.menuSlider.childNodes.forEach(
        (child, index) => {
          const childWidth = child.getBoundingClientRect().width + 25;
          this.menuSliderElementsSizes[index] = childWidth;
          menuSliderWidth += childWidth;
        },
      );
      menuSliderWidth = `${menuSliderWidth}px`;
    }
    Object.keys(this.menuSliderElementsSizes).forEach(
      (el) => {
        this.menuSliderElementsCoords[el] = el === 0 ? 0 : sumPreviousSizes(this.menuSliderElementsSizes, el);
      },
    );
    const presentationItemHeight = `${this.presentationSlider.getBoundingClientRect().height}px`;
    this.setState(
      {
        menuSliderWidth,
        presentationItemHeight,
      },
      () => {
        this[`casaPresentationMenu_${this.currentSlide}`].classList.add('active');
        this[`casaPresentationContent_${this.currentSlide}`].classList.add('enter');
      },
    );
  }

  updateSliderCoords = (newSlide) => {
    this.currentMenuSlide = newSlide;
    let coordsFromMenuElementsWidth = 0;
    Object.keys(this.menuSliderElementsSizes).forEach(
      (el) => {
        if (el < newSlide) {
          coordsFromMenuElementsWidth -= this.menuSliderElementsSizes[el];
        }
      },
    );
    this.menuSlider.style.transform = `translate(${coordsFromMenuElementsWidth}px, 0)`;
  }

  goToSlide = (newSlide, init = false) => {
    const {
      device,
      contentList,
    } = this.props;
    const prevSlide = this.currentSlide;
    const nextSlide = newSlide === contentList.length - 1 ? 0 : newSlide + 1;
    this.currentSlide = newSlide;
    this.currentMenuSlide = newSlide;
    if (init) {
      setTimeout(
        () => {
          this[`casaPresentationMenu_${this.currentSlide}`].classList.add('active');
          this[`casaPresentationContent_${this.currentSlide}`].classList.add('enter');
          this.slideTimeout = new Timer(() => { this.goToSlide(nextSlide); }, 8000);
          this.slideTimeout.start();
        },
        10,
      );
    } else {
      if (device === 'desktop') {
        if (this.slideTimeout !== null) {
          this.slideTimeout.stop();
          this.slideTimeout = null;
        }
      }
      let coordsFromMenuElementsWidth = 0;
      Object.keys(this.menuSliderElementsSizes).forEach(
        (el) => {
          if (el < newSlide) {
            coordsFromMenuElementsWidth -= this.menuSliderElementsSizes[el];
          }
        },
      );
      this.menuSlider.style.transform = `translate(${coordsFromMenuElementsWidth}px, 0)`;
      this[`casaPresentationMenu_${prevSlide}`].classList.remove('active');
      this[`casaPresentationMenu_${this.currentSlide}`].classList.add('active');
      this[`casaPresentationContent_${prevSlide}`].classList.remove('enter');
      this[`casaPresentationContent_${this.currentSlide}`].classList.add('enter');      
    }
    if (device === 'desktop') {
      this.slideTimeout = new Timer(() => { this.goToSlide(nextSlide); }, 8000);
      this.slideTimeout.start();
    }
  }

  mouseEnter = (device) => {
    if (device === 'desktop') {
      this[`casaPresentationMenu_${this.currentSlide}`].classList.add('paused');
      this.slideTimeout.pause();
    }
  }

  mouseLeave = (device) => {
    if (device === 'desktop') {
      this[`casaPresentationMenu_${this.currentSlide}`].classList.remove('paused');
      this.slideTimeout.resume();
    }
  }

  touchStart = (event) => {    
    const touches = event.touches[0];
    this.startX = touches.pageX;
    this.startY = touches.pageY;   
    disableScroll(); 
  }

  touchMove = (event) => {
    const touches = event.touches[0];
    const deltaY = touches.pageY - this.startY;
    const deltaX = touches.pageX - this.startX;
    disableScroll();
    if (Math.abs(deltaY) > 5 && Math.abs(deltaY) > Math.abs(deltaX) / 2) {
      this.deltaX = 0;
    } else {      
      this.dir = deltaX < 0 ? 'next' : 'prev';
      this.deltaX = deltaX;
      let allowSlide = false;
      if (this.dir === 'next') {
        allowSlide = this.currentMenuSlide < Object.keys(this.menuSliderElementsCoords).length - 1;
      } else {
        allowSlide = this.currentMenuSlide > 0;
      }
      if (allowSlide) {
        const menuItemCoords = this.menuSliderElementsCoords[this.currentMenuSlide];
        this.swipeCoords = deltaX + (menuItemCoords * -1);
        this.menuSlider.classList.add('notransition');
        this.menuSlider.style.transform = `translate(${this.swipeCoords}px, 0)`;
      }
    }
  }

  touchEnd = () => { 
    this.menuSlider.classList.remove('notransition');
    let rightSlide = 0;
    if (this.dir === 'next') {
      for (let i = 0; i < Object.keys(this.menuSliderElementsCoords).length; i += 1) {
        const condition = Math.abs(this.swipeCoords) <= this.menuSliderElementsCoords[i];
        if (condition) {
          rightSlide = i;
          break;
        }
      }
    } else {
      for (let i = Object.keys(this.menuSliderElementsCoords).length; i > -1; i -= 1) {
        const condition = Math.abs(this.swipeCoords) >= this.menuSliderElementsCoords[i];
        if (condition) {
          rightSlide = i;
          break;
        }
      }      
    }
    this.updateSliderCoords(rightSlide);
    this.startX = 0;
    this.startY = 0;
    this.deltaX = 0;
    enableScroll();
  }

  render() {
    const {
      device,
      contentList,
    } = this.props;
    const {
      menuSliderWidth,
      presentationItemHeight,
    } = this.state;
    return (
      <div className="sw cpslider">
        <h2 className="CPStaticTitle">Chi siamo</h2>
        <menu ref={(node) => { this.scrollCheckElement = node; }}>
          <div
            className="menuSlider"
            onTouchStart={this.touchStart}
            onTouchMove={this.touchMove}
            onTouchEnd={this.touchEnd}            
          >
            <ul
              ref={(node) => { this.menuSlider = node; }}
              style={{ width: menuSliderWidth }}               
            >
              {
                contentList.map(
                  (menuItem, index) => (
                    <li
                      key={`casapresentation_${Math.random()}`}
                      ref={(node) => { this[`casaPresentationMenu_${index}`] = node; }}
                      className={device}
                    >
                      <button
                        type="button"
                        aria-label={`Vai alla slide: ${menuItem.title}`}
                        onClick={
                          () => {
                            this.goToSlide(index);
                          }
                        }                      
                      >
                        {menuItem.title}
                      </button>
                    </li>
                  ),
                )
              }
            </ul>
          </div>
        </menu>
        <div
          ref={(node) => { this.presentationSlider = node; }}
          className="presentationSlider"
        >
          <ul
            ref={(node) => { this.presentationContainer = node; }}
            className={menuSliderWidth !== 'auto' ? 'presentationSliderReady' : null}
            style={{ height: presentationItemHeight }}
            onMouseEnter={
              () => {
                this.mouseEnter(device);
              }
            }
            onMouseLeave={
              () => {
                this.mouseLeave(device);
              }
            }            
          >
            {
              contentList.map(
                (contentItem, index) => (
                  <li
                    key={`casapresentationItem_${Math.random()}`}
                    ref={(node) => { this[`casaPresentationContent_${index}`] = node; }}
                    style={{ height: presentationItemHeight }}
                  >
                    {
                      index === 0
                        ? (
                          <h1
                            dangerouslySetInnerHTML={{ __html: `${contentItem.content}` }}
                          />
                        )
                        : (
                          <p
                            dangerouslySetInnerHTML={{ __html: `${contentItem.content}` }}
                          />
                        )
                    }
                  </li>
                ),
              )
            }
          </ul>
        </div>
      </div>
    );
  }
}
CPSlider.propTypes = propTypes;
CPSlider.defaultProps = defaultProps;
export default CPSlider;
