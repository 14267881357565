import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import {
  isValidVar,
} from 'shared-utils/src/checkVarType';

import {
  SEOFooterRentHPDesc,
  chooseSeoData,
} from '../../../helpers/SEOPagesHelpers';

const propTypes = {
  firstPos: PropTypes.string,
  secondPos: PropTypes.string,
  numrooms: PropTypes.string,
  onlyAuction: PropTypes.boolean,
};

const defaultProps = {
  firstPos: '',
  secondPos: '',
  numrooms: '',
  onlyAuction: false,
};

const CPStatic = ({
  firstPos,
  secondPos,
  numrooms,
  onlyAuction,
}) => {
  let titleText = `Case in ${firstPos}`;
  let titleHTMLTag = 'h1';
  let descText = firstPos === 'affitto' ? SEOFooterRentHPDesc : 'testo generico';
  let descHTMLTag = 'p';
  const newdata = chooseSeoData({ 
    numrooms, 
    goal: 'forurl', 
    secondPos, 
    onlyAuction,
  });
  if (isValidVar(newdata)) {
    titleText = onlyAuction ? `${newdata.plural}` : `${newdata.plural} in ${firstPos}`;
    titleHTMLTag = 'seofooterTitle' in newdata ? newdata.seofooterTitle[firstPos].tag : 'h1';
    descText = 'seofooterDesc' in newdata ? newdata.seofooterDesc[firstPos].txt : 'testo generico';
    descHTMLTag = 'seofooterDesc' in newdata ? newdata.seofooterDesc[firstPos].tag : 'p';
  }
  const title = (
    <>
      {createElement(
        titleHTMLTag,
        {
          className: 'CPStaticTitle',
        },
        titleText,
      )}
    </>
  );
  const desc = (
    <>
      {createElement(
        descHTMLTag,
        {
          className: 'CPStaticText',
        },
        descText,
      )}
    </>
  );
  return (
    <div className="sw">
      {title}
      {desc}
    </div>
  );
};
CPStatic.propTypes = propTypes;
CPStatic.defaultProps = defaultProps;
export default CPStatic;
