import React from 'react';
import PropTypes from 'prop-types';

import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import HpArrow from 'shared-vectors/icons/HpArrow';
import { Icon } from 'shared-vectors';

import { queryBuilder } from '@modals/factories';

import { useCtxGlobal } from '@client/app/Container';

const PTypes = ({
  availableResults,
  propertyTypes,
  licenseTypeGroups,
}) => {
  const {
    isMobile,
    isSmallMobile,
    openModal,
    pageType,
    filtersProps,
  } = useCtxGlobal();

  const choose = () => openModal && openModal(queryBuilder({
    isMobile,
    isSmallMobile,
    availableResults,
    pageType,
    filtersProps,
    panelChooseBy: {
      isMobile,
      actionClicked: 'propertyTypeGroupSelect',
      pageFrom: pageType,
    },
  }));

  const arrToUse = licenseTypeGroups && licenseTypeGroups.length && propertyTypes.length === 1 && propertyTypes[0] === 'Attività/Licenza (con o senza mura)'
    ? licenseTypeGroups
    : propertyTypes;
  
  return (
    <Grid className="hpFormOption" noWrap>
      <GridItem
        className="hpFormLabel"
        behavior="fixed"
      >
        Cerco
      </GridItem>
      <Grid className="hpFormSelect is-clickable tp--ell" onClick={choose}>
        <GridItem className="tp--ell">
          <Grid>
            <GridItem className="tp--ell">
              {arrToUse[0]}
            </GridItem>
            {
              arrToUse.length > 1
                && (
                  <GridItem behavior="fixed" className="ml--s">
                    +{arrToUse.length - 1}
                  </GridItem>
                )
            }
          </Grid>
        </GridItem>
        <GridItem behavior="fixed" className="pl--m">
          <Icon name={HpArrow} width="12px" height="7px" className="mb--xs" fill />
        </GridItem>
      </Grid>
    </Grid>
  );
};

export default PTypes;

PTypes.propTypes = {
  propertyTypes: PropTypes.instanceOf(Array),
  licenseTypeGroups: PropTypes.instanceOf(Array),
  availableResults: PropTypes.string,
};

PTypes.defaultProps = {
  propertyTypes: [],
  licenseTypeGroups: [],
  availableResults: '0',
};
