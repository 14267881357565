/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

import {
  disableScroll,
  enableScroll,
} from '../../helpers/DOMHelpers';

import './CasaForYou.css';

const propTypes = {
  device: PropTypes.string,
  viewport: PropTypes.instanceOf(Object),
  contentList: PropTypes.instanceOf(Array),
  user: PropTypes.instanceOf(Object),
};

const defaultProps = {
  device: '',
  viewport: {},
  contentList: [],
  user: {},
};

const fakeclick = e => e.preventDefault();

const formatContents = (props) => {
  const {
    user,
    userUrls,
    siteUrl,
    contentList,
  } = props;

  let contents = null;
  if (contentList.length > 0) {
    contents = contentList.map(
      (item) => {
        
        const url = item.id === 'pubblica-annunci' ? (user.isAuthenticated ? `${userUrls.userhost}${userUrls.links && userUrls.links.pubblica.annunci}` : `${siteUrl}/pubblica-annunci`) : item.url;
        const descImg = item.descImg ? <img src={item.descImg} alt={item.descImgTitle} /> : null;
        return (
          <li
            key={`foryou_${Math.random()}`}
            onClick={
              () => {
                if (item.id === 'valuta-immobile') {                  
                  trackGTMEvents({
                    category: 'Valuation',
                    action: 'GoToPropertyValuation',
                    position: 'HP_Box',
                  });
                } else if (item.id === 'trova-agenzia') {
                  trackGTMEvents({
                    category: 'AgencyRecommender',
                    action: 'GoToAgencyRecommender',
                    position: 'HP_Box',
                  });
                }
                document.location = url;
              }
            }
          >
            <a
              className={item.imgclassname}
              href={url}
              title={`${item.title.replace('<br />', ' ')}`}
              onClick={fakeclick}
            >
              <img className="is-max-h--ctr" src={`${item.svgname}`} alt={item.title} />
            </a>
            <h3>
              <a
                href={url}
                title={`${item.title.replace('<br />', ' ')}`}
                onClick={fakeclick}
                dangerouslySetInnerHTML={{ __html: `${item.title}` }}
              />
            </h3>
            <div id="desc-container">
              <p dangerouslySetInnerHTML={{ __html: `${item.desc}` }} />
              {descImg}
            </div>
            <a
              className="button"
              href={url}
              title={item.cta}
              onClick={fakeclick}
            >
              {item.cta}
            </a>
          </li>
        )
      }
    );
  }
  return contents;
};

class CasaForYou extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      sliderWidth: 0,
      sliderContainerCoords: 0,
      sliderItemWidth: null,
      contents: formatContents(props),
    };
    this.dir = 'next';
    this.startX = 0;
    this.startY = 0;
    this.deltaX = 0;
    this.deltaY = 0;
  }

  componentWillReceiveProps(nextProps) {
    const {
      device,
      user,
      viewport: {
        width,
      },
    } = this.props;
    if (
      nextProps.device !== device
      && nextProps.device === 'smartphone'
      && nextProps.viewport.width !== width
    ) {
      this.computeSliderDimensions(nextProps.device);
    }
    if (nextProps.user.isAuthenticated !== user.isAuthenticated) {
      this.setState({
        contents: formatContents(nextProps),
      });
    }
  }

  computeSliderDimensions = () => {
    const sliderWidth = this.slider.getBoundingClientRect().width;
    const sliderItemMargin = 16;
    const sliderItemWidth = sliderWidth + sliderItemMargin;

    this.setState({
      contents: formatContents(this.props),
      sliderWidth,
      sliderItemWidth,
    });
  }

  goToSlide = (newSlide) => {
    const {
      sliderItemWidth,
    } = this.state;
    const newCoords = (sliderItemWidth * newSlide) * -1;
    this.setState({
      currentSlide: newSlide,
      sliderContainerCoords: newCoords,
    });
  }

  touchStart = (event) => {
    const {
      device,
    } = this.props;
    if (device.indexOf('smartphone') > -1) {
      const touches = event.touches[0];
      this.startX = touches.pageX;
      this.startY = touches.pageY;
    }
  }

  touchMove = (event) => {
    const {
      device,
      contentList,
    } = this.props;
    const {
      sliderItemWidth,
      currentSlide,
      sliderContainerCoords,
    } = this.state;
    if (device.indexOf('smartphone') > -1) {
      const touches = event.touches[0];
      const deltaY = touches.pageY - this.startY;
      const deltaX = touches.pageX - this.startX;
      if (Math.abs(deltaY) > 5 && Math.abs(deltaY) > Math.abs(deltaX) / 2) {
        this.deltaX = 0;
      } else {
        disableScroll();
        this.dir = deltaX < 0 ? 'next' : 'prev';
        if ((this.dir === 'next' && currentSlide < contentList.length - 1) || (this.dir === 'prev' && currentSlide > 0)) {
          this.deltaX = deltaX;
          let coords = deltaX + ((sliderItemWidth * currentSlide) * -1);
          if (
            this.dir === 'prev'
            && sliderContainerCoords > coords
          ) {
            coords = sliderContainerCoords;
          }
          this.sliderContainer.classList.add('notransition');
          this.sliderContainer.style.transform = `translate(${coords}px,0)`;
        }
      }
    }
  }

  touchEnd = () => {
    const {
      device,
    } = this.props;
    const {
      currentSlide,
      sliderWidth,
      sliderContainerCoords,
    } = this.state;
    if (device.indexOf('smartphone') > -1) {
      this.sliderContainer.classList.remove('notransition');
      if (Math.abs(this.deltaX) > sliderWidth / 4) {
        const newSlide = this.dir === 'next' ? currentSlide + 1 : currentSlide - 1;
        this.goToSlide(newSlide);
      } else {
        this.sliderContainer.style.transform = `translate(${sliderContainerCoords}px,0)`;
      }
      this.startX = 0;
      this.startY = 0;
      this.deltaX = 0;
      enableScroll();
    }
  }

  render() {
    const {
      contents,
      currentSlide,
      sliderContainerCoords,
    } = this.state;
    return (
      <div className="sw">
        <h2 className="hpSectTitle">Casa.it per te</h2>
        <div
          ref={(node) => { this.slider = node; }}
          className="foryouSlider"
          onTouchStart={this.touchStart}
          onTouchMove={this.touchMove}
          onTouchEnd={this.touchEnd}
        >
          <ul
            ref={(node) => { this.sliderContainer = node; }}
            style={{
              transform: `translate(${sliderContainerCoords}px, 0)`,
            }}
          >
            {contents}
          </ul>
        </div>
        <div className="dotsNavigator">
          <button
            type="button"
            aria-label="Vai alla slide 1"
            className={currentSlide === 0 ? 'active' : null}
            onClick={
              () => {
                this.goToSlide(0);
              }
            }
          >
            <span>1</span>
          </button>
          <button
            type="button"
            aria-label="Vai alla slide 2"
            className={currentSlide === 1 ? 'active' : null}
            onClick={
              () => {
                this.goToSlide(1);
              }
            }
          >
            <span>2</span>
          </button>
          <button
            type="button"
            aria-label="Vai alla slide 3"
            className={currentSlide === 2 ? 'active' : null}
            onClick={
              () => {
                this.goToSlide(2);
              }
            }
          >
            <span>3</span>
          </button>
        </div>
      </div>
    );
  }
}
CasaForYou.propTypes = propTypes;
CasaForYou.defaultProps = defaultProps;
export default CasaForYou;
