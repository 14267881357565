import React from 'react';
import PropTypes from 'prop-types';

import CloseCirc from 'shared-vectors/icons/CloseCirc';
import { Icon } from 'shared-vectors';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import makeClassName from 'shared-utils/src/makeClassName';
import sleep from 'shared-utils/src/sleep';

import { useCtxGlobal } from '@client/app/Container';

import { getActiveFilters } from '@filters/helpers';

import ConfirmDialog from '@async-components/ConfirmDialog';

import './style.scss';

const ResetButton = ({
  locations,
  filters,
  reset,
  setResetDialog,

  coords, 
}) => {
  const { isMobile } = useCtxGlobal();

  const locationsCount = locations.length;
  const filtersCount = getActiveFilters(filters).activeFiltersCount;

  const openConfirmDialog = () => {
    sleep(300).then(() => trackGTMEvents({
      category: 'Interaction',
      action: 'RLS-ResetLastSearch',
    }, null, true));
    setResetDialog(
      <ConfirmDialog
        msg={{
          title: 'Inizia da capo',
          text: 'Vuoi cancellare tutte le voci della tua ricerca e iniziare da capo?',
        }}
        trackData={{
          close: null,
          confirm: {
            category: 'Interaction',
            action: 'RLS-ConfirmResetLastSearch',
          },
        }}
        onClose={() => setResetDialog(null)}
        action={reset}
      />,
    );
  };

  const dataCondition = (
    locationsCount > 0
    || filtersCount > 0
    || !!filters.nearby
  );

  if (dataCondition) {
    const cls = makeClassName([
      'hp_reset',
      !isMobile ? 'hp_reset--variant' : 'hp_reset--variant2',
      !coords.left && 'hp_reset--hid',
    ]);

    const btnCls = makeClassName([
      'hp_reset__btn',
      'tp-s--s',
      'tp-w--m',
      'c-txt--w',
      'is-clickable',
    ]);

    const cta = 'Inizia da capo';

    const usableCoords = {
      top: coords.top,
      left: isMobile ? coords.mobLeft : coords.left,
    };

    return (
      <div className={cls}>
        <div className={btnCls} style={{ ...usableCoords }} onClick={openConfirmDialog}>
          {cta}
          <Icon name={CloseCirc} className="ml--xs" />
        </div>
      </div>
    );
  }

  return null;
};

export default ResetButton;

ResetButton.propTypes = {
  locations: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Object),
  reset: PropTypes.func,
  setResetDialog: PropTypes.func,
  coords: PropTypes.instanceOf(Object),
};

ResetButton.defaultProps = {
  locations: [],
  filters: {},
  reset: () => { },
  setResetDialog: () => { },
  coords: null,
};
