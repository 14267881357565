import React from 'react';
import PropTypes from 'prop-types';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';
import sleep from 'shared-utils/src/sleep';

import DialogQB from '@async-components/DialogQB';

import { getActiveFilters } from '@filters/helpers';
import { getHumanFilters } from '@helpers/search/Search';

import { queryBuilder } from '@components/modals/factories';
import { useCtxGlobal } from '@client/app/Container';

import './style.scss';

const FiltersButton = ({
  filters,
  hasLSSearch,
  submitSearch,
  locations,
  setDialog,
  updateFilters,
}) => {
  const {
    openModal,
    isMobile,
    isSmallMobile,
    filtersProps,
    pageType,
  } = useCtxGlobal();

  const { activeFiltersList, activeFiltersCount } = getActiveFilters(filters);
  const activeFilters = activeFiltersCount
    ? getHumanFilters(activeFiltersList)
    : null;

  const locationsCount = locations.length;

  const openFiltersModal = (inPageBtnClick = false) => openModal &&
    openModal(
      queryBuilder({
        isMobile,
        isSmallMobile,
        filtersProps,
        submitSearch,
        panelChooseBy: {
          isMobile,
          actionClicked: 'filtersCounter',
          pageFrom: pageType,
        },
      }), { doNotPushHistory: !inPageBtnClick },
    );


  const createDialog = () => {
    sleep(300).then(() => {
      window.history.pushState({}, null, null);
      trackGTMEvents({
        category: 'Interaction',
        action: 'OpenModal',
        label: 'RLS-ManageFiltersHP',
      }, null, true);
    });
    setDialog(
      <DialogQB
        filters={filters}
        activeFilters={activeFilters}
        onClose={() => setDialog(null)}
        isMobile={isMobile}
        updateFilters={updateFilters}
        openFiltersModal={openFiltersModal}
        inPage
      />,
    );
  };

  if (hasLSSearch && (!!filters.nearby || activeFiltersCount || locationsCount)) {
    const text = activeFiltersCount === 0
      ? 'Aggiungi filtri'
      : <>+{activeFiltersCount} filtr{activeFiltersCount === 1 ? 'o' : 'i'}</>;

    const action = () => activeFiltersCount === 0
      ? openFiltersModal(true)
      : createDialog();

    return (
      <div className="hp_fb">
        <div className="hp_fb__btn pl--s pr--s tp-s--s tp-w--m c-txt--secondary b-r--m is-clickable" onClick={action}>
          {text}
        </div>
      </div>
    );
  }
  return null;
};

export default FiltersButton;

FiltersButton.propTypes = {
  filters: PropTypes.instanceOf(Object),
  hasLSSearch: PropTypes.bool,
  submitSearch: PropTypes.func,
  locations: PropTypes.instanceOf(Array),
  setDialog: PropTypes.func,
  updateFilters: PropTypes.func,
};

FiltersButton.defaultProps = {
  filters: {},
  hasLSSearch: false,
  submitSearch: () => { },
  locations: [],
  setDialog: () => { },
  updateFilters: () => { },
};
