/* eslint-disable react/prop-types */
import React from 'react';

import trackGTMEvents from 'shared-utils/src/trackGTMEvents';

import { useCtxGlobal } from '@client/app/Container';
import { createRecentSearches } from '@modals/factories';

export default function RecentSearches({ searches }) {
  const { openModal, isMobile, isAuth } = useCtxGlobal();
  const nearbyCheck = isAuth && searches.filter(search => search.nearby !== null).length > 0;
  const open = () => {
    trackGTMEvents(
      'Interaction',
      'OpenModal',
      'SeeRecentSearches',
    );
    openModal(createRecentSearches({
      isMobile,
      searches,
      nearbyCheck,
    }));
  };

  return (
    <button type="button" name="Apri le tue ricerche recenti" className="recentSearchesHandle pr--m pl--m tp-s--s tp-w--m c-txt--w" onClick={open}>
      Ricerche recenti
    </button>
  );
}
