/* eslint-disable react/prop-types */
import React from 'react';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';
import Button from 'shared-components/components/Button';
import Publish from 'shared-vectors/icons/Publish';
import { Icon } from 'shared-vectors';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents/new';

import './style.scss';

export default function SectionPublish({
  action = '',
}) {
  const track = () => trackGTMEvents({
    category: 'Interaction',
    action: 'GoToInsertAdsPage',
    position: 'HPStripe',
  });

  return (
    <div className="c-bg--secondary">
      <Grid className="sect-publish" align="center">
        <GridItem className="sect-publish__txt-clm">
          <h3 className="sect-publish__title tp-w--l tp-s--x2l c-txt--w">
            Fatti trovare da chi sta cercando casa
          </h3>
          <p className="tp-s--x2l c-txt--secondary-l tp-w--m c-txt--f3">
            Pubblica gratis i tuoi annunci!
          </p>
        </GridItem>
        <GridItem className="sect-publish__btn-clm" behavior="fixed">
          <Button className="sect-publish__btn" bg="tr" color="w" border="f3" action={action} onClick={track}>
            <Icon name={Publish} width="32px" className="sect-publish__btn-icon mr--xs" />
            Pubblica annuncio gratis
          </Button>
        </GridItem>
      </Grid>
    </div>
  );
}
