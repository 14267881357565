import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared-components/components/Button';
import Grid from 'shared-components/components/Grid';
import GridItem from 'shared-components/components/GridItem';

import './BlogPosts.scss';

const propTypes = {
  device: PropTypes.string,
  blogList: PropTypes.instanceOf(Array),
  loadBlogImages: PropTypes.bool,
  setRef: PropTypes.func,
};

const defaultProps = {
  device: '',
  blogList: [],
  loadBlogImages: false,
  setRef: () => {},
};

class BlogPosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      currentPanel: 0,
      pageWidth: null,
      itemWidth: null,
      itemMargin: null,
      coords: 0,
      totSlides: props.blogList.length,
      totPanels: props.blogList.length / 3,
      loadBlogImages: false,
    };

    this.dir = 'next';
    this.startX = 0;
    this.startY = 0;
    this.deltaX = 0;
    this.deltaY = 0;
  }

  componentDidMount() {
    this.setDimensions();
    window.addEventListener('resize', this.setDimensions);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loadBlogImages) {
      this.setState({ loadBlogImages: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setDimensions);
  }

  setDimensions = () => {    
    const { pageWidth } = this.state;
    const vw = document.documentElement.clientWidth;
    if (vw !== pageWidth) {
      let containerMargin = 16;
      if (vw > 320) {
        containerMargin = 32;
      }
      let itemMargin = 33;
      let itemWidth = vw - containerMargin - itemMargin - 10;
      if (vw >= 1024) {
        itemMargin = 30;
        itemWidth = 300;
      }
      if (vw >= 1280) {
        itemMargin = 100;
      }
      this.setState({
        pageWidth: vw,
        itemWidth,
        itemMargin,
        currentSlide: 0,
        currentPanel: 0,
        coords: 0,
      });
    }
  }

  elReference = (el) => {
    const { setRef } = this.props;
    this.el = el;
    setRef(el);
  }

  slide = (dir) => {
    const {
      device,
    } = this.props;
    const {
      currentSlide,
      currentPanel,
      itemWidth,
      itemMargin,
    } = this.state;

    const newPanel = dir === 'next'
      ? currentPanel + 1
      : currentPanel - 1;

    const newSlide = dir === 'next'
      ? currentSlide + 1
      : currentSlide - 1;

    const move = device === 'desktop'
      ? (itemWidth + itemMargin) * 3
      : itemWidth + itemMargin;

    const coords = device === 'desktop'
      ? (newPanel * move) * -1
      : (newSlide * move) * -1;

    this.setState({
      coords,
      currentSlide: newSlide,
      currentPanel: newPanel,
    });
  }

  touchStart = (event) => {
    const touches = event.touches[0];
    this.startX = touches.pageX;
    this.startY = touches.pageY;
  }

  touchMove = (event) => {
    const {
      itemWidth,
      itemMargin,
      currentSlide,
      totSlides,
    } = this.state;
    const touches = event.touches[0];
    const deltaY = touches.pageY - this.startY;
    const deltaX = touches.pageX - this.startX;
    if (Math.abs(deltaY) > 5 && Math.abs(deltaY) > Math.abs(deltaX) / 2) {
      this.deltaX = 0;
    } else {
      this.dir = deltaX < 0 ? 'next' : 'prev';
      const nextLimit = currentSlide < totSlides - 1;
      if ((this.dir === 'next' && nextLimit) || (this.dir === 'prev' && currentSlide > 0)) {
        this.deltaX = deltaX;
        const coords = deltaX + (((itemWidth + itemMargin) * currentSlide) * -1);
        this.sliderContainer.classList.add('notransition');
        this.sliderContainer.style.transform = `translate(${coords}px,0)`;
      }
    }
  }

  touchEnd = () => {
    const {
      itemWidth,
      itemMargin,
      coords,
    } = this.state;
    const sliderWidth = itemWidth + itemMargin;
    this.sliderContainer.classList.remove('notransition');
    if (Math.abs(this.deltaX) > sliderWidth / 5) {
      this.slide(this.dir);
    } else {
      this.sliderContainer.style.transform = `translate(${coords}px,0)`;
    }
    this.startX = 0;
    this.startY = 0;
    this.deltaX = 0;
  }

  computeSliderInfos = () => {
    const { device } = this.props;
    const {
      currentSlide,
      currentPanel,
      totSlides,
      totPanels,
    } = this.state;
    return {
      curSlide: device === 'desktop' ? currentPanel + 1 : currentSlide + 1,
      totSlides: device === 'desktop' ? totPanels : totSlides,
    };
  }

  goToBlogPost = (url) => {
    document.location = url;
  }

  render() {
    const { blogList } = this.props;
    const { coords, loadBlogImages } = this.state;
    const { curSlide, totSlides } = this.computeSliderInfos();
    const prevButtonCssClassName = curSlide === 1 ? 'prev disabled' : 'prev';
    const nextButtonCssClassName = curSlide === totSlides ? 'next disabled' : 'next';
    return (
      <div
        id="casablog"
        className="homeslice"
        ref={el => this.elReference(el)}
      >
        <div className="sw">
          <h2 className="hpSectTitle">News dal blog</h2>
          <div
            ref={(node) => { this.slider = node; }}
            className="blItems"
            onTouchStart={this.touchStart}
            onTouchMove={this.touchMove}
            onTouchEnd={this.touchEnd}
          >
            <div
              ref={(node) => { this.sliderContainer = node; }}
              className="blItems__slider"
              style={{ transform: `translate(${coords}px, 0)` }}
            >
              {
                blogList.map(
                  el => (
                    <div
                      key={el.url}
                      className="blItems__slider__item c-bg--w"
                      onClick={() => this.goToBlogPost(el.url)}
                    >
                      <a
                        className="blItems__slider__item__imgcont"
                        href={el.url}
                        title={el.title}
                      >
                        {
                          loadBlogImages
                            && (
                              <img
                                className="blItems__slider__item__img"
                                src={el.img}
                                alt={el.title}
                              />
                            )
                        }
                      </a>
                      <h3 className="blItems__slider__item__tit mt--m mr--m ml--m tp-w--m tp-s--l">
                        <a href={el.url} title={el.title}>
                          {el.title}
                        </a>
                      </h3>
                      <p className="blItems__slider__item__dsc mt--m mr--m ml--m tp-w--s tp-s--m c-txt--f2">{el.desc}</p>
                      <p className="blItems__slider__item__btn blItems__bnt">Leggi tutto</p>
                    </div>      
                  ),
                )
              }
            </div>
          </div>
          <div className="navigation">
            <button
              type="button"
              aria-label="Vai alla slide precedente degli articoli del Blog"
              className={prevButtonCssClassName}
              onClick={() => this.slide('prev')}
            >
              Precedente
            </button>
            <button
              type="button"
              aria-label="Vai alla slide successiva degli articoli del Blog"
              className={nextButtonCssClassName}
              onClick={() => this.slide('next')}
            >
              Successivo
            </button>
            <p>
              <strong>{curSlide}</strong><span>/</span>{totSlides}
            </p>
          </div>
          <Grid className="goToBlog" justify="center">
            <GridItem behavior="fixed">
              <Button
                action="https://blog.casa.it"
                value="Vai al blog"
                title="Il blog di Casa.it: architettura, design, lifestyle e altro ancora"
                size="l"
              />
            </GridItem>
          </Grid>
        </div>
      </div>
    );
  }
}
BlogPosts.propTypes = propTypes;
BlogPosts.defaultProps = defaultProps;
export default BlogPosts;
