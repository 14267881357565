import React from 'react';
import PropTypes from 'prop-types';

import Slider from './slider';
import Static from './static';

import './index.css';

const propTypes = {
  device: PropTypes.string,
  contentList: PropTypes.instanceOf(Array),
  baseUrl: PropTypes.string,
};

const defaultProps = {
  device: '',
  contentList: [],
  baseUrl: '',
};

const CasaPresentation = ({
  baseUrl,
  device,
  contentList,
}) => {
  const noqs = baseUrl.split('?')[0];
  if (
    noqs === '/'
    || noqs === '/liste/vendita'
    || noqs === '/liste/vendita/'
    || baseUrl === '/'
    || baseUrl === '/liste/vendita'
    || baseUrl === '/liste/vendita/'
  ) {
    return (
      <Slider
        device={device}
        contentList={contentList}
      />
    );
  }
  const data = noqs.split('/').filter(part => part !== 'liste').filter(Boolean);
  const [firstPos, secondPos, thirdPos] = data;
  const hasBuildingCondition = thirdPos === 'nuove-costruzioni';
  const onlyAuction = firstPos === 'aste';
  return (
    <Static
      firstPos={firstPos}
      secondPos={!hasBuildingCondition ? secondPos : 'nuove-costruzioni'}
      numrooms={!hasBuildingCondition ? thirdPos : null}
      onlyAuction={onlyAuction}
    />
  );
};

CasaPresentation.propTypes = propTypes;
CasaPresentation.defaultProps = defaultProps;
export default CasaPresentation;
