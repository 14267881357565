import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const propTypes = {
  total: PropTypes.number,
};

const defaultProps = {
  total: 0,
};
const AuctionSplash = ({ total = 0 }) => {

  const roundBasedOnTotal = (number) => {
    const numberLength = Math.floor(Math.log10(Math.abs(number))) + 1;
    const firstNumber = Math.floor(number / (10 ** (numberLength - 1)));
    let divider = 1;
    if (firstNumber >= 5) {
      divider = 10 ** (numberLength - 1);
    } else {
      divider = 10 ** (numberLength - 2);
    }
    return Math.floor(number / divider) * divider;
  };
  
  const totalRound = total ? roundBasedOnTotal(total) : 0;

  return (
    <div
      id="searchContainer"
      className="auctionSplash"
    >
      <p className="auctionHPTitle tp-w--l c-txt--w">
        Aste immobiliari
      </p>
      <p className="auctionHPsubTitle tp-w--m c-txt--w">
        Oltre {totalRound} annunci di immobili all&apos;asta in tutta Italia
      </p>
      <a className="auctionHPAnchor tp-w--m c-txt--w" href="#seoFooter">
        Cerca per provincia
      </a>

    </div>


  );
};

AuctionSplash.propTypes = propTypes;
AuctionSplash.defaultProps = defaultProps;
export default AuctionSplash;
